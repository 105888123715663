import FeedItem from "./FeedItem";
import { Button, CardBody, Container } from "react-bootstrap";
import { Card } from "react-bootstrap";
import React, { useContext } from "react";
import { DataContext } from "../../App";

const FEATURED = [
  "https://traffic.megaphone.fm/APO1054261825.mp3", // "How to Play Magic Ft: StackedTCG"
  "https://traffic.megaphone.fm/APO9988832594.mp3", // "MTG Place Commander Deck Building Guide"
  "https://traffic.megaphone.fm/APO5541538663.mp3", // "EP:17 Commander Staples"
  "https://traffic.megaphone.fm/APO2075713674.mp3", // "BACK To BASICS: SEALED"
];

const RssFeed = ({ feedUrl, top = 10 }) => {
  const feedItems = useContext(DataContext);
  const featuredFeed = feedItems
    .filter((episode) => FEATURED.indexOf(episode.enclosure) > -1)
    .map((item) => (
      <FeedItem
        key={item.title}
        title={item.title}
        description={item.description}
        enclosure={item.enclosure}
      />
    ));

  const recentFeed = feedItems
    .slice(0, top)
    .map((item) => (
      <FeedItem
        key={item.title}
        title={item.title}
        description={item.description}
        enclosure={item.enclosure}
      />
    ));

  return (
    <Container>
      <h1 className="mb-4 text-center">Podcasts</h1>
      <Card className="level-0 border-2">
        <Card.Title className="h1 text-mtg-orange text-center mb-0 mt-3">
          <h3>Featured</h3>
        </Card.Title>
        <CardBody className="py-0 my-0">{featuredFeed}</CardBody>
      </Card>

      <Card className="mt-4 level-0 border-0">
        <Card.Title className="h1 text-mtg-orange text-center mb-0 mt-3">
          <h3>Recent Episodes</h3>
        </Card.Title>
        <CardBody className="py-0 my-0">{recentFeed}</CardBody>
      </Card>
      <div className="pb-3">
        <Button
          className="mx-auto d-block w-50 border-mtg-orange"
          variant="outline-dark"
          href="https://open.spotify.com/show/6kMZvNAFbYwA3x1QnmvC7n?si=LjIZUHCkQS-jcDzzU7jTkQ"
        >
          More Episodes
        </Button>
      </div>
    </Container>
  );
};

export default RssFeed;
