import React, { useState, useEffect } from "react";
import { DEFAULT_DATA } from "../../common/Constants";
import LandscapeLayout from "./LandscapeLayout";
import GameConfig from "./GameConfig";
import HelpModal from "./HelpModal";

const LifeCounter = () => {
  const [configModalShow, setConfigModalShow] = React.useState(false);
  const [helpModalShow, setHelpModalShow] = React.useState(false);

  const [data, setData] = useState(() => {
    // Load the data from localStorage when the component mounts
    const savedData = localStorage.getItem("MTGPlaceLifeCounter");
    return savedData ? JSON.parse(savedData) : DEFAULT_DATA;
  });

  // Save the data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("MTGPlaceLifeCounter", JSON.stringify(data));
  }, [data]);

  // Reset data to default
  const resetData = () => {
    setData(DEFAULT_DATA);
  };

  // Add or subtract the player's health
  const changeHealth = (playerId, change) => {
    const updatedPlayers = data.players.map((player) => {
      if (player.id === playerId) {
        return {
          ...player,
          health: player.health + change,
        };
      } else {
        return player;
      }
    });
    setData({ ...data, players: updatedPlayers });
  };

  // Add or Subtract one from the player's poison
  const changePoison = (playerId, change) => {
    const updatedPlayers = data.players.map((player) => {
      if (player.id === playerId) {
        return {
          ...player,
          poison: Math.max(0, player.poison + change),
        };
      } else {
        return player;
      }
    });
    setData({ ...data, players: updatedPlayers });
  };

  // handle commander attacks
  const [personAttacking, setPersonAttacking] = useState(null);
  const [selectedDamage, setSelectedDamage] = useState(0);

  // Add or subtract 'change' from the player's commander damage matrix from the from_player_id
  const changeCommanderDamageMatrix = (playerId, from_player_id, change) => {
    // Create a new array with updated players
    const updatedPlayers = data.players.map((player) => {
      // Check if the current player is the one we want to update
      if (player.id === playerId) {
        // Update the damage from the from_player_id in the commanderDamageMatrix
        const updatedCommanderDamageMatrix = {
          ...player.commanderDamageMatrix,
          [from_player_id]: Math.max(
            0,
            (player.commanderDamageMatrix[from_player_id] || 0) + change
          ),
        };

        // Return a new object with the updated commanderDamageMatrix
        return {
          ...player,
          health: player.health - change, // Change is always a positive number here
          commanderDamageMatrix: updatedCommanderDamageMatrix,
        };
      }

      // If the current player is not the one we want to update, return it as is
      return player;
    });

    // Update the state with the updated players
    setData({ ...data, players: updatedPlayers });
  };

  const changeName = (playerId, newName) => {
    const updatedPlayers = data.players.map((player) => {
      if (player.id === playerId) {
        return {
          ...player,
          name: newName,
        };
      } else {
        return player;
      }
    });
    setData({ ...data, players: updatedPlayers });
  };

  return (
    <>
      <GameConfig
        show={configModalShow}
        onHide={() => setConfigModalShow(false)}
        data={data}
        setData={setData}
        resetData={resetData}
      />
      <HelpModal show={helpModalShow} onHide={() => setHelpModalShow(false)} />
      <LandscapeLayout
        players={data.players}
        changeName={changeName}
        changeHealth={changeHealth}
        changeCommanderDamageMatrix={changeCommanderDamageMatrix}
        changePoison={changePoison}
        setConfigModalShow={setConfigModalShow}
        setHelpModalShow={setHelpModalShow}
        personAttacking={personAttacking}
        setPersonAttacking={setPersonAttacking}
        selectedDamage={selectedDamage}
        setSelectedDamage={setSelectedDamage}
        commander={data.commander}
      />
    </>
  );
};

export default LifeCounter;
