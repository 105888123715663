import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RssFeed from "./pages/podcasts/RssFeed";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import AboutUs from "./pages/about_us/AboutUs";
import ErrorPage from "./pages/error_page/ErrorPage";
import LifeCounter from "./pages/life_counter/LifeCounter";
import MtgNav from "./navigation/MtgNav";
import { Analytics } from "@vercel/analytics/react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MtgNav />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <AboutUs />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/home",
        element: <AboutUs />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/podcasts",
        element: <RssFeed feedUrl="https://anchor.fm/s/6936ae20/podcast/rss" />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/lifecounter",
    element: <LifeCounter />,
    errorElement: <ErrorPage />,
  },
]);

// Create a context
export const DataContext = createContext();

// Fetch data function
const fetchData = async (url) => {
  const response = await fetch(url);
  const data = await response.text();

  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(data, "text/xml");
  const episodes = xmlDoc.querySelectorAll("item");
  const episodes_array = [];

  episodes.forEach((episode) => {
    const itunesNamespace = "http://www.itunes.com/dtds/podcast-1.0.dtd";
    const seasonElement = episode.getElementsByTagNameNS(
      itunesNamespace,
      "season"
    )[0];

    const episodeElement = episode.getElementsByTagNameNS(
      itunesNamespace,
      "episode"
    )[0];

    episodes_array.push({
      season: seasonElement ? seasonElement.textContent : null,
      id: episodeElement ? episodeElement.textContent : null,
      title: episode.querySelector("title")?.textContent,
      description: episode.querySelector("description")?.textContent,
      enclosure: episode.querySelector("enclosure").getAttribute("url"),
    });
  });

  episodes_array.sort((a, b) => {
    if (a.season === b.season) {
      return b.id - a.id;
    } else {
      return b.season - a.season;
    }
  });

  return episodes_array;
};

function App() {
  const [data, setData] = useState([]);

  // Fetch data when the App mounts
  useEffect(() => {
    fetchData("https://anchor.fm/s/6936ae20/podcast/rss").then(setData);
  }, []);

  return (
    <div className="App">
      <DataContext.Provider value={data}>
        <RouterProvider router={router} />
      </DataContext.Provider>
      <Analytics />
    </div>
  );
}

export default App;
