import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import cx from "classnames";

const CommanderContent = ({
  show,
  id,
  setDisplayMatrixModal,
  personAttacking,
  setPersonAttacking,
}) => {
  const handleCommanderButtonClick = () => {
    if (personAttacking == id) {
      setPersonAttacking(null);
    } else {
      setPersonAttacking(id);
    }
  };

  return (
    <div
      className={`player-commander w-100 position-relative p-1 d-flex flex-column ${
        !show ? "d-none" : ""
      }`}
    >
      <Button
        variant="outline-danger"
        className={cx("flex-grow-1 p-1 m-0 commander-attack-button", {
          "not-selected": personAttacking != id,
        })}
        onClick={() => {
          handleCommanderButtonClick();
        }}
      >
        <i className="bi bi-crosshair"></i>
      </Button>
      <Button
        variant="outline-info"
        className="flex-grow-1 p-1 m-0 text-commander-yellow border-commander-yellow"
        onClick={() => setDisplayMatrixModal(true)}
      >
        <i className="bi bi-card-list"></i>
      </Button>
    </div>
  );
};

export default CommanderContent;
