import { Button } from "react-bootstrap";
import cx from "classnames";

const CommanderActionOverlay = ({
  players,
  playerName,
  showDefenderButton,
  showAttackerButton,
  personAttacking,
  setPersonAttacking,
  personDefending,
  selectedDamage,
  setSelectedDamage,
  changeCommanderDamageMatrix,
  playerOrientationMatrix,
}) => {
  const handleCommanderAttack = (personDefending, personAttacking, damage) => {
    changeCommanderDamageMatrix(personDefending, personAttacking, damage);
    setSelectedDamage(0);
    setPersonAttacking(null);
  };

  const flipDefenderButton =
    playerOrientationMatrix[personDefending] !=
    playerOrientationMatrix[personAttacking];

  return (
    <>
      <Button
        variant="danger"
        className={cx("position-absolute w-100 h-100 z-popover", {
          "d-none": !showDefenderButton,
        })}
        style={{
          transform: flipDefenderButton ? "rotate(180deg)" : "",
        }}
        onClick={() =>
          handleCommanderAttack(
            personDefending,
            personAttacking,
            selectedDamage
          )
        }
      >
        <p>{playerName}</p>
        <p>Current damage from your commander</p>
        <p>
          <i className="bi bi-shield-slash-fill text-commander-yellow"></i>
          &nbsp;
          {players[personDefending]?.commanderDamageMatrix?.[personAttacking]}
        </p>
      </Button>
      <div
        className={cx("position-absolute w-100 h-100 z-popover", {
          "d-none": !showAttackerButton,
        })}
      >
        <Button
          variant="primary"
          className="position-absolute top-0 start-0 end-0 top-commander-button"
          style={{ height: "51%" }}
          onClick={() => setSelectedDamage(selectedDamage + 1)}
        >
          <i className="bi bi-plus-lg"></i>
        </Button>

        <Button
          variant="primary"
          className="position-absolute bottom-0 start-0 end-0 bottom-commander-button"
          style={{ height: "51%" }}
          onClick={() => setSelectedDamage(Math.max(0, selectedDamage - 1))}
        >
          <i className="bi bi-dash-lg"></i>
        </Button>

        <div
          className="text-center position-absolute top-50 start-50 translate-middle"
          style={{ pointerEvents: "none" }}
        >
          <i className="bi bi-shield-slash-fill text-commander-yellow"></i>
          &nbsp;{selectedDamage}
        </div>

        <Button
          variant="danger"
          className="position-absolute top-0 end-0"
          style={{ height: "25%", border: "none" }}
          onClick={() =>
            handleCommanderAttack(
              personDefending,
              personAttacking,
              selectedDamage
            )
          }
        >
          Yourself
        </Button>
      </div>
    </>
  );
};

export default CommanderActionOverlay;
