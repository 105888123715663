import { Card } from "react-bootstrap";

const FeedItem = ({ title, description, enclosure }) => {
  return (
    <Card className="mx-auto my-3 p-3 text-center">
      <Card.Title className="text-mtg-orange">{title}</Card.Title>
      <Card.Body>
        {description.replace(/(<([^>]+)>)/gi, "").split("About us")[0]}
      </Card.Body>
      <audio controls className="mx-auto audio-player w-100">
        <source src={enclosure} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </Card>
  );
};

export default FeedItem;
