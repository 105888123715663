import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { DEFAULT_DATA } from "../../common/Constants";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar } from "react-bootstrap";
import nav_logo from "../../assets/MTGPlace.png";

const GameConfig = ({ show, onHide, data, setData, resetData }) => {
  const [commander, setCommander] = useState(
    data ? data.commander : DEFAULT_DATA.commander
  );

  const [players, setPlayers] = useState(
    data ? data.players : DEFAULT_DATA.players
  );

  useEffect(() => {
    setPlayers(data ? data.players : DEFAULT_DATA.players);
    setCommander(data ? data.commander : DEFAULT_DATA.commander);
  }, [data]);

  const resetCommanderDamageMatrix = (newPlayerArray) => {
    const matrix = {};
    newPlayerArray.forEach((player) => {
      matrix[player.id] = 0;
    });

    return [
      ...newPlayerArray.map((player) => ({
        ...player,
        commanderDamageMatrix: matrix,
      })),
    ];
  };

  const addPlayer = () => {
    if (players.length >= 6) {
      return;
    }
    const newPlayers = resetCommanderDamageMatrix([
      ...players,
      {
        id: Math.max(...players.map((player) => player.id)) + 1,
        name: "New Player",
        health: commander ? 40 : 20,
        poison: 0,
        commanderDamageMatrix: {},
      },
    ]);
    setPlayers(newPlayers);
    setData({ players: newPlayers, commander: commander });
  };

  const removePlayer = () => {
    if (players.length <= 2) {
      return;
    }
    // Remove the player with the highest id
    const newPlayers = resetCommanderDamageMatrix(
      players.filter(
        (player) =>
          player.id !== Math.max(...players.map((player) => player.id))
      )
    );

    setPlayers(newPlayers);
    setData({ players: newPlayers, commander: commander });
  };

  const toggleCommander = () => {
    setData((prevData) => ({
      players: resetCommanderDamageMatrix(
        prevData.players.map((player) => ({
          ...player,
          health: commander ? 20 : 40,
          poison: 0,
        }))
      ),
      commander: !prevData.commander,
    }));
  };

  const newGame = () => {
    const newPlayers = players.map((player, index) => ({
      ...player,
      id: index,
      health: commander ? 40 : 20,
      poison: 0,
    }));
    setPlayers(newPlayers);
    setData({
      players: resetCommanderDamageMatrix(newPlayers),
      commander: commander,
    });
  };

  return (
    <Modal
      className="config-modal user-select-none"
      fullscreen="sm-down"
      show={show}
      onHide={onHide}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <LinkContainer to="/Home">
            <Navbar.Brand>
              <img className="nav-service-logo" src={nav_logo} alt="logo" />
            </Navbar.Brand>
          </LinkContainer>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="text-left">
          <Form.Check
            className="text-start mb-3"
            reverse
            type="switch"
            id="commanderSwitch"
            label="Commander"
            checked={commander}
            onChange={() => {
              setCommander(!commander);
              toggleCommander();
            }}
          />
          <div className="d-flex align-items-center">
            <Form.Label className="me-auto">Players</Form.Label>
            <Button
              variant="danger"
              id="remove-player"
              className="ms-auto"
              onClick={removePlayer}
              disabled={players.length <= 2}
            >
              <i className="bi bi-person-dash-fill"></i>
            </Button>
            <div className="d-inline px-4">{players.length}</div>
            <Button
              variant="primary"
              id="add-player"
              onClick={addPlayer}
              disabled={players.length >= 6}
            >
              <i className="bi bi-person-plus-fill"></i>
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            newGame();
            onHide();
          }}
        >
          New Game
        </Button>
        {/* Factory Reset */}
        <Button
          variant="danger"
          onClick={() => {
            resetData();
            onHide();
          }}
        >
          Factory Reset
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GameConfig;
