import React from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar } from "react-bootstrap";
import nav_logo from "../../assets/MTGPlace.png";

const HelpModal = ({ show, onHide }) => {
  return (
    <Modal
      className="config-modal user-select-none"
      fullscreen="sm-down"
      show={show}
      onHide={onHide}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <LinkContainer to="/Home">
            <Navbar.Brand>
              <img className="nav-service-logo" src={nav_logo} alt="logo" />
            </Navbar.Brand>
          </LinkContainer>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroup.Item>
            <i className="bi bi-heart-fill p-2 text-health-red px-1"></i>
            <strong>: Life</strong>
          </ListGroup.Item>
          <ListGroup.Item>
            <i className="bi bi-shield-slash-fill text-commander-yellow px-1"></i>
            <strong>: Commander Damage</strong>
          </ListGroup.Item>
          <ListGroup.Item>
            <i className="bi bi-crosshair text-attack-red"></i>
            <strong> : Attack with Commander</strong>
          </ListGroup.Item>
          <ListGroup.Item>
            <i className="bi bi-card-list text-commander-yellow"></i>
            <strong> : Display breakdown of commander damage received</strong>
          </ListGroup.Item>
          <ListGroup.Item>
            <i className="bi bi-droplet-fill text-poison-green px-1"></i>
            <strong>: Poison</strong>
          </ListGroup.Item>
          <ListGroup.Item>
            To enable fullscreen mode on a mobile device for an optimal user
            experience, add this website to your home screen!
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default HelpModal;
