import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import React, { useEffect } from "react";
import "./PlayerCardStyles.css";
import cx from "classnames";

const PlayerContentNav = ({
  commander,
  health,
  maxCommanderDamage,
  poison,
  displayHealth,
  displayCommander,
  displayPoison,
  setDisplayHealth,
  setDisplayCommander,
  setDisplayPoison,
}) => {
  useEffect(() => {
    if (!commander) {
      setDisplayHealth(true);
      setDisplayCommander(false);
      setDisplayPoison(false);
    }
  }, [commander, setDisplayHealth, setDisplayCommander, setDisplayPoison]);

  return (
    <ButtonGroup vertical className="player-content-nav flex-shrink-0">
      <Button
        variant="dark"
        className={cx("player-content-nav-item", {
          selected: displayHealth,
        })}
        active={displayHealth}
        onClick={() => {
          setDisplayHealth(true);
          setDisplayCommander(false);
          setDisplayPoison(false);
        }}
      >
        <i className="bi bi-heart-fill p-2 text-health-red px-1"></i>
        {!displayHealth ? health : null}
      </Button>
      <Button
        variant="dark"
        className={cx("player-content-nav-item", {
          selected: displayCommander,
          "d-none": !commander,
        })}
        active={displayCommander}
        onClick={() => {
          setDisplayHealth(false);
          setDisplayCommander(true);
          setDisplayPoison(false);
        }}
      >
        <i className="bi bi-shield-slash-fill text-commander-yellow px-1"></i>
        {maxCommanderDamage}
      </Button>
      <Button
        variant="dark"
        className={cx("player-content-nav-item", {
          selected: displayPoison,
        })}
        active={displayPoison}
        onClick={() => {
          setDisplayHealth(false);
          setDisplayCommander(false);
          setDisplayPoison(true);
        }}
      >
        <i className="bi bi-droplet-fill text-poison-green px-1"></i>
        {!displayPoison ? poison : null}
      </Button>
    </ButtonGroup>
  );
};

export default PlayerContentNav;
