import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const PromoPopup = ({ title, body, hoursUntilReset = 24, test = false }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    localStorage.setItem("lastShown", Date.now());
  };

  useEffect(() => {
    const lastShown = localStorage.getItem("lastShown");
    const hoursSinceLastShown = (Date.now() - lastShown) / 1000 / 60 / 60;

    if (!lastShown || hoursSinceLastShown > hoursUntilReset || test) {
      // 24 hours
      setShow(true);
    }
  }, []);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{ __html: body }} />
      </Modal>
    </>
  );
};

export default PromoPopup;
