import { Card, Container, Row, Col, Image } from "react-bootstrap";
import cx from "classnames";

const BioCard = ({ info }) => {
  let socials = [
    "website",
    "twitch",
    "twitter",
    "instagram",
    "tiktok",
    "hover",
    "youtube",
  ];

  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title>{info.name}</Card.Title>
        <Card.Subtitle className="text-muted">{info.monikers}</Card.Subtitle>
        <Container className="mt-2 p-0">
          <Row>
            <Col
              sm={3}
              className={cx("test-class", {
                "d-none": info.image_path == null,
              })}
            >
              <Image src={info.image_path} rounded fluid />
            </Col>
            <Col>
              <Card.Text as={"div"}>{info.blurb}</Card.Text>
              {socials.map((social) => {
                if (info[social]) {
                  return (
                    <Card.Link key={social} href={info[social]}>
                      {social}
                    </Card.Link>
                  );
                } else {
                  return null;
                }
              })}
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default BioCard;
