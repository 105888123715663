import React from "react";
import Button from "react-bootstrap/Button";

const HealthContent = ({ health, show, id, changeHealth }) => {
  return (
    <div
      className={`player-health w-100 position-relative p-1 ${
        !show ? "d-none" : ""
      }`}
    >
      {/* Plus/minus health buttons */}
      <Button
        variant="muted"
        className="position-absolute top-0 start-0 end-0"
        style={{ height: "50%", border: "none" }}
        onClick={() => changeHealth(id, 1)}
      >
        <i className="bi bi-plus-lg"></i>
      </Button>
      <Button
        variant="muted"
        className="position-absolute bottom-0 start-0 end-0"
        style={{ height: "50%", border: "none" }}
        onClick={() => changeHealth(id, -1)}
      >
        <i className="bi bi-dash-lg"></i>
      </Button>

      {/* Health display */}
      <div className="text-center position-absolute top-50 start-50 translate-middle">
        <i className="bi bi-heart-fill p-2 text-health-red"></i>&nbsp;{health}
      </div>
    </div>
  );
};

export default HealthContent;
