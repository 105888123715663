import { Container, Row, Col } from "react-bootstrap";
import IconicLink from "../../common/IocnicLink";

const Links = () => {
  return (
    <Container>
      <hr />
      <Row>
        <Col sm={12} md={6} lg={3}>
          Listen or Watch:
          <ul style={{ listStyleType: "none", paddingLeft: "1em" }}>
            <li>
              <IconicLink
                address="https://open.spotify.com/show/6kMZvNAFbYwA3x1QnmvC7n?si=LjIZUHCkQS-jcDzzU7jTkQ"
                text="Podcast"
                icon="bi-spotify"
              />
            </li>
            <li>
              <IconicLink
                address="https://www.youtube.com/channel/UCS9tOSBjBZk8oOt_im6OKkA"
                text="YouTube"
                icon="bi-youtube"
              />
            </li>
            <li>
              <IconicLink
                address="https://www.whatnot.com/user/mtgplace"
                text="Whatnot"
              />
            </li>
          </ul>
        </Col>

        <Col sm={12} md={6} lg={3}>
          Follow:
          <ul style={{ listStyleType: "none", paddingLeft: "1em" }}>
            <li>
              <IconicLink
                address="https://www.facebook.com/amtgplace"
                text="Facebook"
                icon="bi-facebook"
              />
            </li>
            <li>
              <IconicLink
                address="https://www.tiktok.com/@mtgplace"
                text="Tiktok"
              />
            </li>
            <li>
              <IconicLink
                address="https://www.instagram.com/mtgplace"
                text="Instagram"
                icon="bi-instagram"
              />
            </li>
            <li>
              <IconicLink
                address="https://twitter.com/a_mtg_place?s=21"
                text="Twitter"
                icon="bi-twitter-x"
              />
            </li>
          </ul>
        </Col>

        <Col sm={12} md={6} lg={3}>
          Support:
          <ul style={{ listStyleType: "none", paddingLeft: "1em" }}>
            <li>
              <IconicLink
                address="https://www.patreon.com/MTGPlace"
                text="Patreon"
              />
            </li>
            <li>
              <IconicLink
                address="https://tcgplayer.com/?utm_campaign=affiliate&utm_medium=MTGPlace&utm_source=MTGPlace"
                text="Shop TCG"
                icon="bi-cart3"
              />
            </li>
            <li>
              <IconicLink
                address="https://zen.ai/themtgplace"
                text="zencastr"
              />
            </li>
            <li>
              <IconicLink
                address="https://bitdefender.f9tmep.net/mtgplace"
                text="bit defender"
              />
            </li>
            <li>
              <IconicLink
                address="https://ubisoft.pxf.io/mtgplace"
                text="Ubisoft"
              />
            </li>
          </ul>
        </Col>

        <Col sm={12} md={6} lg={3}>
          Contact:
          <ul style={{ listStyleType: "none", paddingLeft: "1em" }}>
            <li>
              <IconicLink
                address="https://discord.gg/nPRfUGDyqx"
                text="Join Discord"
                icon="bi-discord"
              />
            </li>
            <li>
              <IconicLink
                address="mailto: amtgplace@gmail.com"
                text="Shoot us an email"
                icon="bi-envelope-at"
              />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Links;
