import { Button } from "react-bootstrap";
import React from "react";
import Card from "react-bootstrap/Card";
import { useState } from "react";
import PlayerContentNav from "./PlayerContentNav";
import HealthContent from "./HealthContent";
import PoisonContent from "./PoisonContent";
import CommanderContent from "./CommanderContent";
import "./PlayerCardStyles.css";
import Form from "react-bootstrap/Form";
import MatrixModal from "./MatrixModal";
import CommanderActionOverlay from "./CommanderActionOverlay";

const PlayerCard = ({
  id,
  name,
  changeName,
  health,
  commanderDamageMatrix,
  poison,
  changeHealth,
  changeCommanderDamageMatrix,
  changePoison,
  rotationDegrees,
  players,
  personAttacking,
  setPersonAttacking,
  selectedDamage,
  setSelectedDamage,
  commander,
  playerOrientationMatrix,
}) => {
  // Handle displaying health, poison, and commander tabs
  const [displayHealth, setDisplayHealth] = useState(true);
  const [displayPoison, setDisplayPoison] = useState(false);
  const [displayCommander, setDisplayCommander] = useState(false);

  const [displayMatrixModal, setDisplayMatrixModal] = useState(false);

  // Handle editing name
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(name);

  const handleEdit = () => {
    setIsEditing(true);
    // TODO: Focus the Form.Control
  };

  const handleSave = () => {
    changeName(id, editName);
    setIsEditing(false);
  };

  const fontSize =
    players.length < 3 ? "fs-1" : players.length < 5 ? "fs-2" : "";

  return (
    <>
      <Card
        className="player-card mx-1 user-select-none position-relative"
        style={{
          flex: 1,
          transform: `rotate(${rotationDegrees})`,
        }}
      >
        <Card.Header className="position-relative d-flex justify-content-center align-items-center p-0">
          {isEditing ? (
            <Form className="flex-grow-1 text-center">
              <Form.Control
                type="text"
                placeholder={name}
                onChange={(e) => setEditName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSave();
                  }
                }}
                maxLength={15}
              />
            </Form>
          ) : (
            <div className="flex-grow-1 text-center">{name}</div>
          )}
          {isEditing ? (
            <Button
              variant="muted"
              onClick={handleSave}
              className="ms-auto flex-grow-0"
            >
              <i className="bi bi-floppy-fill"></i>
            </Button>
          ) : (
            <Button
              variant="muted"
              onClick={handleEdit}
              className="ms-auto flex-grow-0"
            >
              <i className="bi bi-pencil-square"></i>
            </Button>
          )}
        </Card.Header>
        <Card.Body className={`d-flex flex-row p-0 ${fontSize}`}>
          <HealthContent
            health={health}
            show={displayHealth}
            id={id}
            changeHealth={changeHealth}
          />
          <CommanderContent
            show={displayCommander}
            id={id}
            setDisplayMatrixModal={setDisplayMatrixModal}
            personAttacking={personAttacking}
            setPersonAttacking={setPersonAttacking}
          />
          <PoisonContent
            poison={poison}
            show={displayPoison}
            id={id}
            changePoison={changePoison}
          />
          <PlayerContentNav
            health={health}
            maxCommanderDamage={Math.max(
              ...Object.values(commanderDamageMatrix)
            )}
            commander={commander}
            poison={poison}
            displayHealth={displayHealth}
            displayCommander={displayCommander}
            displayPoison={displayPoison}
            setDisplayHealth={setDisplayHealth}
            setDisplayCommander={setDisplayCommander}
            setDisplayPoison={setDisplayPoison}
          />
        </Card.Body>
        <CommanderActionOverlay
          players={players}
          playerName={name}
          showDefenderButton={personAttacking != id && personAttacking != null}
          showAttackerButton={personAttacking === id}
          personAttacking={personAttacking}
          setPersonAttacking={setPersonAttacking}
          selectedDamage={selectedDamage}
          setSelectedDamage={setSelectedDamage}
          personDefending={id}
          changeCommanderDamageMatrix={changeCommanderDamageMatrix}
          playerOrientationMatrix={playerOrientationMatrix}
        />
      </Card>
      <MatrixModal
        show={displayMatrixModal}
        rotationDegrees={rotationDegrees}
        onHide={() => setDisplayMatrixModal(false)}
        id={id}
        commanderDamageMatrix={commanderDamageMatrix}
        changeCommanderDamageMatrix={changeCommanderDamageMatrix}
        players={players}
      />
    </>
  );
};

export default PlayerCard;
