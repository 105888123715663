import React from "react";
import { Modal, Button } from "react-bootstrap";

const MatrixModal = ({
  show,
  rotationDegrees,
  onHide,
  id,
  commanderDamageMatrix,
  changeCommanderDamageMatrix,
  players,
}) => {
  const playerName = (player_id) => {
    return players.find((player) => player.id == player_id).name;
  };
  const matrixRow = (from_player_id) => {
    return (
      <div key={from_player_id} className="d-flex flex-row">
        <div className="flex-shrink-0">{playerName(from_player_id)}</div>
        <Button
          variant="muted"
          className="ms-auto"
          style={{ height: "50%", border: "none" }}
          onClick={() => changeCommanderDamageMatrix(id, from_player_id, -1)}
        >
          <i className="bi bi-dash-lg"></i>
        </Button>
        <div
          className="flex-shrink-0 text-center my-auto"
          style={{ width: "20px" }}
        >
          {commanderDamageMatrix[from_player_id]}
        </div>
        <Button
          variant="muted"
          className=""
          style={{ height: "50%", border: "none" }}
          onClick={() => changeCommanderDamageMatrix(id, from_player_id, 1)}
        >
          <i className="bi bi-plus-lg"></i>
        </Button>
      </div>
    );
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="user-select-none"
      centered
      style={{ transform: `rotate(${rotationDegrees})` }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Commander Damage</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Damage {playerName(id)} has <strong>received</strong>:
        </p>
        {Object.keys(commanderDamageMatrix).map((from_player_id) => {
          return matrixRow(from_player_id);
        })}
      </Modal.Body>
    </Modal>
  );
};

export default MatrixModal;
