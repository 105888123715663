import logo from "../../assets/MTGPlacebanner.png";
import { Container } from "react-bootstrap";
import Links from "./Links";
import Bios from "./Bios";

const AboutUs = () => {
  return (
    <Container>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <Bios />
      <Links />
    </Container>
  );
};

export default AboutUs;
