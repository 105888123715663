import BioCard from "./BioCard";
import corey from "./../../assets/Corey2.jpg";
import josh from "./../../assets/Josh2.jpg";
import james from "./../../assets/James.jpg";

const coreyBlurb = () => {
  return (
    <p>
      Hi, Im Corey the creator and host of the MTG Place Podcast. I started
      Playing Magic in 1999 with the Starter 99 and 7th edition starter decks (
      <a href="https://scryfall.com/card/7ed/224/trained-orgg">Trained Orgg</a>{" "}
      vs <a href="https://scryfall.com/card/7ed/110/vizzerdrix">Vizzerdrix</a>).
      I used to play in a lot of competitive magic events as a early adult and
      finished in the top 10 several times. My favorite formats are Limited,
      Commander, and Legacy and my favorite Card is{" "}
      <a href="https://scryfall.com/card/chk/122/kokusho-the-evening-star">
        Kokusho, the evening star
      </a>
      .
    </p>
  );
};

const HOSTS = [
  {
    name: "Corey",
    image_path: corey,
    monikers: "MTG Corey, Pokerbrox, Poker",
    blurb: coreyBlurb(),
    website: null,
    twitch: null,
    twitter: null,
    instagram: null,
    tiktok: null,
    hover: null,
    youtube: null,
  },
  {
    name: "Josh",
    image_path: josh,
    monikers: "MTG Josh, SavageHunter77",
    blurb:
      "Hey everyone, I mostly do Vintage Cube (the best format) but I'll play other formats. Such as Pauper, Timeless, or anything fun going on.",
    website: null,
    twitch: "https://m.twitch.tv/savagehunter77?desktop-redirect=true",
    twitter: null,
    instagram: null,
    tiktok: null,
    hover: null,
    youtube: null,
  },
  {
    name: "James",
    image_path: james,
    monikers: "MTG James, Th3Pum4M4n",
    blurb:
      "Hey there, I'm Puma! I am a furry, and I also am very much interested in music, tech and overall sci-fi. I am currently looking into streaming, and plan on trying it out someday soon! I love playing RPGs, shooters, and also card games like MTG and Hearthstone.",
    website: null,
    twitch: null,
    twitter: "https://twitter.com/Th3Pum4M4n",
    instagram: null,
    tiktok: null,
    hover: null,
    youtube: null,
  },
];
const Bios = () => {
  return (
    <>
      <p className="m-3">
        <strong>Who We Are:</strong> Friends who grew up together playing MTG on
        the kitchen table. Now, we still play on our kitchen table together, but
        our wives, partners, and kids play with us.
      </p>

      <p className="m-3">
        <strong>Why Magic the Gathering:</strong> MTG brought us together as
        kids and helps keeps our friendship alive through casual game play, home
        tournaments, and having a safe place to be ourselves and love enjoy the
        game. We want to bring that to you, our listeners, in every podcast,
        recorded match, or discord thread. Our goal is to create a hub for our
        listeners to learn more about MTG, the new sets, different formats to
        play, or just geek out on the cool cards.
      </p>
      <p className="m-3">
        Have an idea on what we should record next? Send us an email at
        <a href="mailto:amtgplace@gmail.com"> amtgplace@gmail.com</a>.
      </p>
      {HOSTS.map((data) => {
        return <BioCard key={data.name} info={data} />;
      })}
    </>
  );
};

export default Bios;
