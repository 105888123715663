import { Row, Col, Button } from "react-bootstrap";
import { useState } from "react";

const ActionBar = ({ setConfigModalShow, setHelpModalShow }) => {
  const [fullscreen, setFullscreen] = useState(false);
  const isFullscreen = () => {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const goFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      // Firefox
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      // IE/Edge
      document.documentElement.msRequestFullscreen();
    }
  };

  const toggleFullscreen = () => {
    if (fullscreen) {
      exitFullscreen();
    } else {
      goFullscreen();
    }
    setFullscreen(!fullscreen);
  };

  const fullScreenToggleIcon = () => {
    return fullscreen ? (
      <i
        className="bi bi-fullscreen-exit text-mtg-orange"
        style={{ fontSize: "1.5em" }}
      ></i>
    ) : (
      <i
        className="bi bi-arrows-fullscreen text-mtg-orange"
        style={{ fontSize: "1.5em" }}
      ></i>
    );
  };

  return (
    <>
      <Row className=" user-select-none flex-row text-center align-middle px-3 my-0">
        <Col>
          {/* <Button
            type="button"
            variant="dark"
            className="level-0 border-0 p-0"
            onClick={() => {
              toggleFullscreen();
            }}
          >
            {fullScreenToggleIcon()}
          </Button> */}
        </Col>
        <Col>
          <Button
            type="button"
            variant="dark"
            data-toggle="modal"
            data-target="#gameConfigModal"
            className="level-0 border-0 p-0"
            onClick={() => {
              setConfigModalShow(true);
            }}
          >
            <i
              className="bi bi-gear-fill text-mtg-orange"
              style={{ fontSize: "1.5em" }}
            ></i>
          </Button>
        </Col>
        <Col>
          <Button
            type="button"
            variant="dark"
            className="level-0 border-0 p-0"
            onClick={() => {
              setHelpModalShow(true);
            }}
          >
            <i
              className="bi bi-question-circle text-mtg-orange"
              style={{ fontSize: "1.5em" }}
            ></i>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ActionBar;
