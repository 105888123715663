const IconicLink = ({ address, text, icon = "bi-box-arrow-up-right" }) => {
  return (
    <a className="icon-link icon-link-hover" href={address}>
      {text}
      <i className={`px-1 bi ${icon}`}></i>
    </a>
  );
};

export default IconicLink;
