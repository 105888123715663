import React from "react";
import { Container } from "react-bootstrap";
import PlayerCard from "./player_card/PlayerCard";
import ActionBar from "./ActionBar";

const LandscapeLayout = ({
  players,
  changeName,
  changeHealth,
  changeCommanderDamageMatrix,
  changePoison,
  setConfigModalShow,
  setHelpModalShow,
  personAttacking,
  setPersonAttacking,
  selectedDamage,
  setSelectedDamage,
  commander,
}) => {
  // top and bottom player cards, horizontal settings bar in the middle
  const middleIndex = Math.ceil(players.length / 2);
  const playersOnTop = players.slice(middleIndex);
  const playersOnBottom = players.slice(0, middleIndex);
  const playerOrientationMatrix = players.reduce((acc, player) => {
    acc[player.id] = playersOnTop.includes(player) ? "top" : "bottom";
    return acc;
  }, {});

  return (
    <Container
      fluid
      className="landscape p-3 d-flex flex-column h-100 w-100 fs-6"
    >
      <Container fluid className="p-0 d-flex flex-row w-100 h-100">
        {playersOnTop.map((player) => {
          return (
            <PlayerCard
              key={player.id}
              id={player.id}
              name={player.name}
              health={player.health}
              commanderDamageMatrix={player.commanderDamageMatrix}
              poison={player.poison}
              rotationDegrees={"180deg"}
              changeName={changeName}
              changeHealth={changeHealth}
              changeCommanderDamageMatrix={changeCommanderDamageMatrix}
              changePoison={changePoison}
              players={players}
              personAttacking={personAttacking}
              setPersonAttacking={setPersonAttacking}
              selectedDamage={selectedDamage}
              setSelectedDamage={setSelectedDamage}
              commander={commander}
              playerOrientationMatrix={playerOrientationMatrix}
            />
          );
        })}
      </Container>
      <ActionBar
        setConfigModalShow={setConfigModalShow}
        setHelpModalShow={setHelpModalShow}
      />
      <Container fluid className="p-0 d-flex flex-row w-100 h-100">
        {playersOnBottom.map((player) => {
          return (
            <PlayerCard
              key={player.id}
              id={player.id}
              name={player.name}
              health={player.health}
              commanderDamageMatrix={player.commanderDamageMatrix}
              poison={player.poison}
              rotationDegrees={"0deg"}
              changeName={changeName}
              changeHealth={changeHealth}
              changeCommanderDamageMatrix={changeCommanderDamageMatrix}
              changePoison={changePoison}
              players={players}
              personAttacking={personAttacking}
              setPersonAttacking={setPersonAttacking}
              selectedDamage={selectedDamage}
              setSelectedDamage={setSelectedDamage}
              commander={commander}
              playerOrientationMatrix={playerOrientationMatrix}
            />
          );
        })}
      </Container>
    </Container>
  );
};

export default LandscapeLayout;
