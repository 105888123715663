export const DEFAULT_DATA = {
  players: [
    {
      id: 1,
      name: "Player 1",
      health: 20,
      poison: 0,
      commanderDamageMatrix: { 1: 0, 2: 0 },
    },
    {
      id: 2,
      name: "Player 2",
      health: 20,
      poison: 0,
      commanderDamageMatrix: { 1: 0, 2: 0 },
    },
  ],
  commander: false,
};
