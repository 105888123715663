import React from "react";
import Button from "react-bootstrap/Button";

const PoisonContent = ({ poison, show, id, changePoison }) => {
  return (
    <div
      className={`player-poison w-100 position-relative p-1 ${
        !show ? "d-none" : ""
      }`}
    >
      {/* Plus/minus poison buttons */}
      <Button
        variant="muted"
        className="position-absolute top-0 start-0 end-0"
        style={{ height: "50%", border: "none" }}
        onClick={() => changePoison(id, 1)}
      >
        <i className="bi bi-plus-lg"></i>
      </Button>
      <Button
        variant="muted"
        className="position-absolute bottom-0 start-0 end-0"
        style={{ height: "50%", border: "none" }}
        onClick={() => changePoison(id, -1)}
      >
        <i className="bi bi-dash-lg"></i>
      </Button>

      {/* Poison Display */}
      <div className="text-center position-absolute top-50 start-50 translate-middle">
        <i className="bi bi-droplet-fill p-2 text-poison-green"></i>
        {poison}
      </div>
    </div>
  );
};

export default PoisonContent;
